module.exports = {
    host     : 'ic-master.cskuwdauaxiv.us-east-1.rds.amazonaws.com',
    user     : 'andyb',
    password : 'Nz&0fEkK=I6rG5GE$MQEFr&VAR9aBh!',
    database : 'myip_ai',
    openai   : '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',
    bdc      : 'bdc_cee7d27acc8049fda38d2dedd752a219',

    projName            : 'MyIP.ai',                                          //used for disclaimer etc (actual name of project)
    projCopy            : '2025',
    projDomain          : 'myip.ai',                          //used for cookie 
    projUrl             : 'https://myip.ai/',                                 //base url
    projEmail           : 'hal@myip.ai',
    projCompany         : 'Name Metrics Ltd',
    projCompanyDeets    : 'regd. in England company: 9908640, VAT: GB258989131',
    projCompanyEmail    : 'andy@namemetrics.com',
    projCookieName      : 'myip',
    projCookieExpire    : 3600*24*1000,
}